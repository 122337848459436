import LoginSuccess from './LoginSuccess';
import Home from './Home';
import {
  useSearchParams,
  Routes,
  Route
} from "react-router-dom";

import './App.css';

function App() {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      { /*<Navigation queryString={ searchParams }/>*/ }
      <br/>
      <Routes>
        <Route path="/" element={ <Home queryString={ searchParams } />} />
        <Route path="/login-success" element={ <LoginSuccess/> } />
        {/*<Route path="/transfers" element={ <DataTransfers queryString={ searchParams } />} />*/}
      </Routes>      
    </>
  );
}

export default App;
