import React from 'react';
import { Container, Alert, Button } from 'react-bootstrap';

class LoginSuccess extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log("Component LoginSuccess mounted!");
  }

  render() {
    return(
      <>
      <Container>
        <Alert variant="primary">
          <p>Login with Google was successful! Please close this Browser window and return to Shopware.</p>
          { /*<Button onClick={ this.handleCloseButtonClick } variant="outline-primary" size="lg">
            Close Browser Window
            </Button>*/ }
        </Alert>
      </Container> 
      </>
    );
  }

  handleCloseButtonClick() {
    //TODO check how the UX flow is best
  }
}

export default LoginSuccess;
