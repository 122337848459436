import React from 'react';
import { Container, Col, Row, Badge, Alert, Button, ListGroup } from 'react-bootstrap';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      error: null,
      shopId: null,
      shopUrl: null,
      registrationStatus: null,
      queryString: props.queryString,
      googleLoginClicked: false,
    };

    this.handleLoginButtonClick = this.handleLoginButtonClick.bind(this);
  }

  componentDidMount() {
    console.log("Component Home mounted!");
    console.log("queryString: " +this.state.queryString.toString());
    fetch("https://api.shopware-engage.dev/account/" +this.state.queryString.get("shop-id") +"?" +this.state.queryString.toString())
      .then(res => res.json())
      .then(
        (result) => {
          if (result.shopId!=null) {
            this.setState({
              isLoaded: true,
              shopId: result.shopId,
              shopUrl: result.shopUrl,
              registrationStatus: result.registrationStatus
            });
          } else {
            this.setState({
              isLoaded: true,
              error: result
            })
          }
          window.parent.postMessage('sw-app-loaded', '*');
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
          window.parent.postMessage('sw-app-loaded', '*');
        }
      )
  }

  render() {
    const { error, isLoaded, shopId, shopUrl, registrationStatus, googleLoginClicked } = this.state;
    if (error) {
      return <Container><Alert variant="danger">Error: {error.message}</Alert></Container>;
    } else if (!isLoaded) {
      return <Container><Alert variant="info">Loading...</Alert></Container>;
    } else if (registrationStatus==="Linked") {
      return (
        <Container>
          <Row>
            <Col xs={2}><h6>Shop-Id:</h6></Col>
            <Col><h6>{shopId}</h6></Col>
          </Row>
          <Row>
            <Col xs={2}><h6>Shop-URL:</h6></Col>
            <Col><h6>{shopUrl}</h6></Col>
          </Row>
          <br/>      
          <ListGroup as="ul">
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Customer</div>
                Latest synchronization at <Badge bg="secondary" pill>2022-04-08 22:47:01</Badge> with <Badge bg="primary" pill>14</Badge> items was <Badge bg="success" pill>OK</Badge>
              </div>
              
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Product</div>
                Latest synchronization at <Badge bg="secondary" pill>2022-04-07 08:02:30</Badge> with <Badge bg="primary" pill>133</Badge> items was <Badge bg="danger" pill>NOT OK</Badge>
              </div>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Order</div>
                Latest synchronization at <Badge bg="secondary" pill>2022-04-08 23:40:16</Badge> with <Badge bg="primary" pill>2</Badge> items was <Badge bg="success" pill>OK</Badge>
              </div>
            </ListGroup.Item>
          </ListGroup>          
        </Container>        
      );
    } else if (googleLoginClicked) {
      return (
        <Container>
          <Alert variant="primary">
            <p>Please reload this page once Google login was successful. Either press F5 or click the reload icon of your Browser.</p>
          </Alert>
        </Container>        
      );
    } else {
      return (
        <Container>
          <Alert variant="primary">
            <p>Welcome! You have successfully installed Shopware-Engage 🎉</p>
            <p>As a last step you need to grant the app access to your Google account.
            This access is needed to synchronize Shopware data to Google Sheets as data source for AppSheet.</p>
            <p><b>Note:</b> You only grant the app permissions to read/write data to Google Sheets, nothing more.</p>
            <br/>
            <p>To keep you secure, by pressing the button below a new Browser window will open:</p>
            <Button onClick={ this.handleLoginButtonClick } variant="outline-primary" size="lg">
              Login with Google 
            </Button>
          </Alert>
        </Container>        
      );
    }
  }

  handleLoginButtonClick() {
    window.open("https://api.shopware-engage.dev/login-google/?" +this.state.queryString.toString());
    this.setState({ googleLoginClicked: true });
  }
}

export default Home;